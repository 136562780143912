import React from 'react';
import moment from 'moment';
import { UIAlertsFilters, UIReportsFilters } from '../dtos/insights-filter-conversion';
import { UIAnomaliesFilter } from '../dtos/anomalies.dto';
import { DATE_OPTIONS, DATE_OPTIONS_MAP } from './constants';
import DOMPurify from 'dompurify';
import { CATEGORY_VALUES, DateData, TimeRange } from './model';
import { v4 as uuidv4 } from 'uuid';

export const parseContent = (content) => {
    const regex = /(<br\/>|<b>[^<]*<\/b>)/g;
    const elements = content.split(regex);

    return elements.map((element, index) => {
        if (element.startsWith('<br/>')) {
            return React.createElement('br', { key: uuidv4() });
        } else if (element.startsWith('<b>')) {
            const contentBold = element.slice(3, -4);
            const sanitizedContentBold = DOMPurify.sanitize(contentBold);
            return React.createElement('b', { key: uuidv4() }, sanitizedContentBold);
        } else {
            return React.createElement('span', { key: uuidv4() }, element);
        }
    });
};

export const convertHtmlContentResToValidDom = (res) => {
    // Extract the content inside the <body> tag, if it exists
    const bodyContentMatch = /<body[^>]*>([\s\S]*?)<\/body>/i.exec(res);
    const bodyContent = bodyContentMatch ? bodyContentMatch[1] : res;

    // Remove <html>, </html>, <o:p>, and </o:p> tags from the content (including any variations with spaces or new lines)
    return bodyContent.replace(/<\/?\s*(html|o:p)\s*>/g, '');
}

export const convertInitials = (name: string) => {
    return name.match(/(?<=^|,)\s*[A-Za-z]/g)?.map(s => s.trim()).reverse().join('').toUpperCase() || '';
}

export const decodeAndCleanHtmlContent = (content: string) => {
    const decodedContent = decodeURIComponent(content.replace(/\+/g," "));
    return convertHtmlContentResToValidDom(decodedContent);
}

export const parseValues = (inputValue: string) => {
    return inputValue.split(/[,;\n]/).map((v) => v.trim()).filter((v) => v !== '');
};

export const formatDate = (date: string, ignoreTime?: boolean) => {
    const format = `MM/DD/YYYY${ignoreTime ? '' : ' HH:mm'}`;
    return date ? moment.utc(date).local().format(format) : '';
}

const toUtcMoment = (date) => {
    return moment.utc().set({
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate()
    });
}

export const toStartOfDayInUtc = (date) => {
    return toUtcMoment(date).startOf('day').valueOf().toString();
}

export const toEndOfDayInUtc = (date) => {
    return toUtcMoment(date).endOf('day').valueOf().toString();
}

export const handleDateRange = (dateRange) => {
    let fromDate, toDate;

    if (typeof dateRange === 'string') {
        const duration = DATE_OPTIONS_MAP[dateRange];

        if (duration.dayOffsetFilter) {
            fromDate = moment().utc().subtract(duration.value, duration.unit).startOf('day').valueOf().toString();
            toDate = moment().utc().subtract(duration.dayOffsetFilter, 'days').endOf('day').valueOf().toString();
        } else {
            fromDate = moment().utc().subtract(duration.value, duration.unit).valueOf().toString();
            toDate = moment().utc().valueOf().toString();
        }
    } else {
        fromDate = toStartOfDayInUtc(dateRange.from);
        toDate = toEndOfDayInUtc(dateRange.to);
    }

    return { fromDate, toDate };
}

export const createBaseWidgetUrl = (baseUrl: string, oem: string, apn: string, dateRange: any, worldRegion: string, ratType: string, isShowPrivateIP: boolean) => {
    const { fromDate, toDate } = handleDateRange(dateRange);

    return baseUrl.replaceAll('{oem}', oem)
        .replace('{apn}', apn)
        .replace('{from}', fromDate)
        .replace('{to}', toDate)
        .replace('{world_region}', worldRegion)
        .replace('{ratType}', ratType)
        .replace('{is_show_private_ip}', String(isShowPrivateIP));
}

export const dateRangeDescription = (dateRange: any) => {
    if (typeof dateRange === 'string') {
        return dateRange;
    } else {
        return `${formatDate(dateRange.from, true)} - ${formatDate(dateRange.to, true)}`;
    }
}

const convertDateRange = (dateRange) => {
    if (dateRange && typeof dateRange !== 'string') {
        dateRange.from = new Date(dateRange.from);
        dateRange.to = new Date(dateRange.to);
    }
    return dateRange;
}

export const convertSavedFilterToObject = (storedFilter: string) => {
    let filter = JSON.parse(storedFilter);
    filter.dateRange = convertDateRange(filter.dateRange);
    return filter;
}

export const convertSavedFilterToMap = (storedFilter: string) => {
    let parsedFilter = JSON.parse(storedFilter);
    let filterMap = new Map<any, UIAnomaliesFilter | UIReportsFilters | UIAlertsFilters>(parsedFilter);

    filterMap.forEach((filter) => {
        filter.dateRange = convertDateRange(filter.dateRange);
    });

    return filterMap;
}

export const convertRangeSelectedToRangeObj = (val: number)=> {
    const duration = DATE_OPTIONS[val];
    const from = moment().utc().subtract(duration.value + 1, duration.unit as moment.DurationInputArg2).startOf('day').valueOf();
    const to = moment().utc().subtract(2, 'days').endOf('day').valueOf();
    return { from, to };
}

export const convertPointsToAxis = (dataList:any[], CHART_HEIGHT: number) => {
    const totalPoints = dataList?.length;

    const counts = dataList?.map(item => item.count);
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);

    return dataList?.map((item: { count: number, date: string }, index:number) => ({
        x: (index / (totalPoints - 1)) * 100,
        y: maxCount !== minCount
            ? ((item.count - minCount) / (maxCount - minCount)) * CHART_HEIGHT
            : CHART_HEIGHT / 2
    }));
};

export const formatNumber = (num: any): string => {
    if (num >= 1_000_000) {
        return `${(num / 1_000_000).toFixed(2)}M`;
    } else if (num >= 1_000) {
        return `${(num / 1_000).toFixed(2)}K`;
    } else {
        return num?.toLocaleString();
    }
};

export const fillMissingDates = (data: DateData[], dateRange: TimeRange): DateData[] => {
    const startDate = moment.utc(dateRange.from);
    const endDate = moment.utc(dateRange.to);
    const dateMap: Record<string, DateData[]> = {};

    // Initialize the map with existing data
    data.forEach(item => {
        const dateStr = moment.utc(item.date).format('YYYY-MM-DDT00:00:00Z');
        if (!dateMap[dateStr]) {
            dateMap[dateStr] = [];
        }
        dateMap[dateStr].push(item);
    });

    // Iterate over the date range and fill missing dates
    let currentDate = startDate;
    while (currentDate <= endDate) {
        const dateStr = currentDate.format('YYYY-MM-DDT00:00:00Z');
        if (!dateMap[dateStr]) {
            dateMap[dateStr] = [{ date: dateStr, count: 0 }];
        }
        currentDate = currentDate.add(1, 'days');
    }

    // Convert the map back to an array and sort by date
    return Object.values(dateMap).flat().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
};

export const aggregateDataByWeek = (data, dateRangeObj) => {
    const weeklyData = [];
    const dateMap = {};

    const startDate = moment(dateRangeObj.from);
    const endDate = moment(dateRangeObj.to);
    const numberOfWeeks = 4;
    const weekDuration = 7;

    // Initialize dateMap for each week in the range
    for (let i = 0; i < numberOfWeeks; i++) {
        const weekStart = startDate.clone().add(i * weekDuration, 'days').format('YYYY-MM-DD');
        dateMap[weekStart] = {}
        dateMap[weekStart][CATEGORY_VALUES[0]] = { date: weekStart, categoryName: CATEGORY_VALUES[0], count: 0 };
    }

    data.forEach(item => {
        const itemDate = moment(item.date);
        const category = item.categoryName;

        if (itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate)) {
            const weekIndex = Math.floor(itemDate.diff(startDate, 'days') / weekDuration);
            const weekRange = startDate.clone().add(weekIndex * weekDuration, 'days').format('YYYY-MM-DD');

            if (!dateMap[weekRange]) {
                dateMap[weekRange] = {};
            }

            if (!dateMap[weekRange][category]) {
                dateMap[weekRange][category] = { date: weekRange, categoryName: category, count: 0 };
            }

            dateMap[weekRange][category].count += item.count;
        }
    });

    for (const weekRange in dateMap) {
        for (const category in dateMap[weekRange]) {
            weeklyData.push(dateMap[weekRange][category]);
        }
    }

    return weeklyData;
};

