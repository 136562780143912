import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import './bar-chart.scss';
import { CATEGORIES_CONFIG } from '../security-dashboard.constant';
import { DateData } from '../../../../utils/model';

export interface BarChartData extends DateData {
    categoryName: string;
}

interface BarChartProps {
    data: BarChartData[];
    rangeType: 'daily' | 'weekly';
}

const BarChart: React.FC<BarChartProps> = ({ data, rangeType }) => {
    const ref = useRef<SVGSVGElement | null>(null);
    const chartContainer = 700;
    const chartHeight = 250;
    const barWidth = 10;
    const gap = 4;
    const uniqueDates = Array.from(new Set(data.map(d => d.date)));
    const totalBarWidth = (barWidth + gap) * CATEGORIES_CONFIG.length - gap;
    const chartWidth = Math.max(chartContainer, uniqueDates.length * (totalBarWidth + 20));

    useEffect(() => {
        const svg = d3.select(ref.current);
        const margin = { top: 20, right: 10, bottom: 20, left: 30 };
        const width = chartWidth - margin.left - margin.right;
        const height = chartHeight - margin.top - margin.bottom;

        svg.attr('viewBox', `0 0 ${chartWidth} ${chartHeight}`)
            .attr('preserveAspectRatio', 'xMidYMid meet');

        const defs = svg.append('defs');

        // Define gradients
        CATEGORIES_CONFIG.forEach(config => {
            const linearGradient = defs.append('linearGradient')
                .attr('id', config.gradientId)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '0%')
                .attr('y2', '100%');

            linearGradient.append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config.color)
                .attr('stop-opacity', 1);

            linearGradient.append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config.color)
                .attr('stop-opacity', 0);
        });

        const g = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1);

        const y = d3.scaleLinear()
            .rangeRound([height, 0]);

        const color = d3.scaleOrdinal()
            .domain(CATEGORIES_CONFIG.map(config => config.fieldName))
            .range(CATEGORIES_CONFIG.map(config => `url(#${config.gradientId})`));

        const maxIssues = d3.max(data, d => d.count) || 0;
        const roundedMax = Math.ceil(maxIssues / 5) * 5 || 5; // Round up to the nearest multiple of 5

        x0.domain(data.map(d => d.date));
        y.domain([0, roundedMax]);

        const tooltip = d3.select('body').append('div')
            .attr('class', 'bar-tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background', 'rgba(0, 0, 0, 0.7)')
            .style('color', 'white')
            .style('padding', '5px')
            .style('border-radius', '5px')
            .style('pointer-events', 'none');

        g.append('g')
            .selectAll('g')
            .data(data)
            .enter().append('g')
            .attr('transform', d => `translate(${x0(d.date)},0)`)
            .append('rect')
            .attr('x', (d) => {
                const index = CATEGORIES_CONFIG.findIndex(category => category.fieldName === d.categoryName);
                return (x0.bandwidth() - totalBarWidth) / 2 + index * (barWidth + gap) - gap;
            })
            .attr('y', d => y(d.count))
            .attr('width', barWidth)
            .attr('height', d => height - y(d.count))
            .attr('fill', d => color(d.categoryName))
            .attr('rx', 5)
            .attr('ry', 5)
            .on('mouseover', function(event, d) {
                tooltip.style('visibility', 'visible')
                    .text(d.count);
            })
            .on('mousemove', function(event) {
                tooltip.style('top', (event.pageY - 10) + 'px')
                    .style('left', (event.pageX + 10) + 'px');
            })
            .on('mouseout', function() {
                tooltip.style('visibility', 'hidden');
            });

        g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x0).tickFormat((d, i) => {
                if (rangeType === 'weekly') {
                    const weekStart = moment(d);
                    const weekEnd = moment(d).add(6, 'days');
                    const formattedStart = weekStart.format('MMM D');
                    const formattedEnd = weekEnd.format('MMM D');
                    return `${formattedStart} - ${formattedEnd}`;
                } else {
                    return moment(d).format('MMM D');
                }
            }))
            .selectAll('text')
            .attr('fill', 'white')
            .style('font-size', '14px')
            .style('font-family', 'ATTAleckSansMd');

        g.append('g')
            .attr('class', 'axis axis--y')
            .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('d'))) // Display only numbers, 5 ticks
            .selectAll('text')
            .attr('fill', 'white')
            .style('font-size', '14px')
            .style('font-family', 'ATTAleckSansMd');

        // Remove the y-axis line
        g.select('.axis--y path').remove();
        // Remove the x-axis line
        g.select('.axis--x path').remove();
        g.selectAll('.axis line')
            .attr('stroke', 'rgba(255, 255, 255, 0.1)'); // Light grid lines

        // Update the x-axis tick lines
        g.selectAll('.axis--x line')
            .attr('y2', 19)
            .attr('stroke', 'white')
            .attr('transform', 'translate(0, -15)'); // Move the tick lines 15px above

        // Add horizontal grid lines
        g.selectAll('.grid')
            .data(y.ticks(5))
            .enter().append('line')
            .attr('class', 'grid')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', y)
            .attr('y2', y)
            .attr('stroke', 'rgba(255, 255, 255, 0.1)')
            .attr('stroke-width', 1);

        // Center x-axis ticks
        svg.selectAll(".axis--x .tick")
            .attr("transform", (d) => {
                return "translate(" + (x0(d) + x0.bandwidth() / 2 - (barWidth - gap) / 2 + 5) + ",0)";
            });
    }, [data, rangeType]);

    return (
        <div className="bar-chart-container">
            <svg ref={ref} style={{width: `${chartWidth}px`, height: `${chartHeight}px`}}></svg>
        </div>
    );
};

export default BarChart;